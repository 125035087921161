import { useEffect, useCallback, useState } from 'react'
import { useFormik } from 'formik'
import { useAuthErrorMessage, setNewPassword, reauthenticateByCredential } from '@hooks/auth'
import usePasswordVisibility from '@hooks/usePasswordVisibility'
import { useNotifications } from '@context/notifications'
import * as yup from 'yup'

export const resetPasswordValidationSchema = yup.object({
  currentPassword: yup.string('Enter your current password').required('Current password is required'),
  newPassword: yup
    .string('Enter your new password')
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(/[A-Z]/, 'Password should have at least one uppercase letter')
    .matches(/[a-z]/, 'Password should have at least one lowercase letter')
    .matches(/[0-9]/, 'Password should have at least one number')
    .required('New password is required'),
})

export const useResetPasswordForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const { enqueue } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [firebaseErrorCode, setFirebaseErrorCode] = useState()

  const { errorMessage, clearMessage } = useAuthErrorMessage(firebaseErrorCode)
  const { showPassword, onVisibilityToggle, showConfirmation, handleMouseDownPassword } = usePasswordVisibility()

  const onSubmit = useCallback(
    values => {
      setSubmitted(false)
      setLoading(true)
      clearMessage()
      setFirebaseErrorCode()
      reauthenticateByCredential(values.currentPassword)
        .then(() => {
          setNewPassword(values.newPassword)
            .then(() => enqueue({ variant: 'filled', body: 'Password has been successfully updated!' }))
            .catch(error => setFirebaseErrorCode(error))
            .finally(() => setLoading(false))
        })
        .catch(error => setFirebaseErrorCode(error))
        .finally(() => setLoading(false))
    },
    [clearMessage, enqueue],
  )

  useEffect(() => {
    if (errorMessage) {
      enqueue({ variant: 'filled', body: errorMessage, severity: 'error' })
      clearMessage()
    }
  }, [errorMessage, enqueue, clearMessage])

  useEffect(() => {
    if (submitted) {
      formik.resetForm()
      const values = {
        currentPassword: '',
        newPassword: '',
      }
      formik.setValues(values)
    }
  }, [formik, submitted])

  const formik = useFormik({
    initialValues: { password: '', passwordConfirmation: '' },
    validationSchema: resetPasswordValidationSchema,
    onSubmit,
  })

  return { formik, loading, onVisibilityToggle, showPassword, showConfirmation, handleMouseDownPassword }
}
