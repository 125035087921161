import React from 'react'
import { Link } from 'gatsby'
import { Typography, Link as MuiLink } from '@mui/material'
import { TitleWrapper } from '../Common'

import { PageWrapper } from '../Motion'

const OrdersPage = () => (
  <PageWrapper>
    <TitleWrapper>
      <Typography component="h2" variant="h4">
        Orders
      </Typography>
    </TitleWrapper>

    <Typography>
      New and updated Orders page coming soon to My Account. Please view current Order Status page for order history by{' '}
      <MuiLink component={Link} to="/order/status">
        clicking here
      </MuiLink>
    </Typography>
  </PageWrapper>
)

export default OrdersPage
