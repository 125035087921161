import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  useTheme,
  useMediaQuery,
  alpha,
  Box,
  CircularProgress,
} from '@material-ui/core'
import classNames from 'classnames'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import { Link, Redirect } from '@gatsbyjs/reach-router'
import Breadcrumbs from '@templates/strapi-cms/content-types/Breadcrumbs'
import { navigate } from 'gatsby'
// helpers
import { lockBodyScroll, unlockBodyScroll } from '@helpers/bodyscroll'
import { useModalsContext } from '@context/modalContext'
import { useFirebaseContext } from '@context/firebaseContext'
import links from './desktopLinks'
// content components
import { FramerRouter } from '../Motion'
import MobileAppBar from '../MobileAppBar'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& *': {
      outline: 'none !important',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    '& > span': {
      color: '#f2f2f2',
      display: 'block',
      padding: '16px 20px',
      fontWeight: 600,
      fontSize: '1.4rem',
    },
  },
  paper: {
    position: 'sticky',
    top: '147px',
    zIndex: 1,
    height: '75vh',
    justifyContent: 'space-between',
  },
  content: {
    overflowX: 'hidden',
    backgroundColor: '#fff',
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '75vh',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '80px',
      margin: '0 -0.9375rem',
    },
  },
  link: {
    position: 'relative',
    zIndex: 1,
    '&[data-active="true"]:after': {
      content: "''",
      position: 'absolute',
      display: 'inline-block',
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.primary.main,
      width: '4px',
      zIndex: 2,
    },
    '&[data-active="true"] svg': {
      color: theme.palette.primary.main,
    },
  },
  bcWrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: `10px 0`,
    },
    '& nav ol li:nth-last-child(2)': {
      display: 'inline-block !important',
      height: '1.2rem',
      verticalAlign: 'middle',
    },
    '& nav ol li:last-child': {
      display: 'inline-block !important',
      height: '1.2rem',
      verticalAlign: 'middle',
    },
  },
}))

const onClick = path => e => {
  if (typeof window !== 'undefined') {
    if (window.location.pathname === path) {
      e.preventDefault()
      return
    }
    lockBodyScroll()
    setTimeout(() => {
      unlockBodyScroll()
    }, 1000)
  }
}

const breadcrumbData = [{ label: 'My Account', level: 1, category: 'accounts' }]

const AccountsDrawer = () => {
  const { user, loading } = useFirebaseContext()
  const showPageDefault = !_isEmpty(user) && !_isUndefined(user)
  const { dispatch, actions } = useModalsContext()
  const [showPage, setShowPage] = useState(showPageDefault)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  useEffect(() => {
    if (!loading) {
      if (!_isEmpty(user) && !_isUndefined(user)) {
        setShowPage(true)
      } else {
        navigate('/')
      }
    }
  }, [user, loading])

  if (loading && !showPage) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '40vh',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="4rem" />
      </Box>
    )
  }

  return (
    <>
      <div className={classes.bcWrapper}>
        <Breadcrumbs productBreadcrumbs={breadcrumbData} />
      </div>
      <div className={classes.root}>
        {!isMobile && (
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, classes.drawerOpen)}
            classes={{
              paper: classNames(classes.paper, classes.drawerOpen),
            }}
          >
            <div>
              <div className={classes.toolbar}>
                <Typography component="span" aria-hidden="true">
                  My Account
                </Typography>
              </div>
              <List disablePadding>
                {links.map(
                  item =>
                    item?.label !== 'default' && (
                      <ListItem
                        button
                        className={classes.link}
                        component={Link}
                        to={item.path}
                        key={item.id}
                        onClick={onClick(item.path)}
                        getProps={({ isCurrent }) => ({
                          style: isCurrent ? { backgroundColor: alpha(theme.palette.primary.main, 0.1) } : {},
                          'data-active': isCurrent ? 'true' : 'false',
                        })}
                      >
                        <ListItemIcon>
                          <item.icon />
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                      </ListItem>
                    ),
                )}
              </List>
            </div>
            <div>
              <Divider />
              <List disablePadding>
                <ListItem button onClick={() => dispatch({ type: actions.logout })}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Log Out" />
                </ListItem>
              </List>
            </div>
          </Drawer>
        )}
        <main className={classes.content}>
          <MobileAppBar />
          <FramerRouter>
            <Redirect from="/" to="/account/profile" noThrow />
            {links.map(item => (
              <item.component key={`routes-${item.id}`} path={item?.path?.replace?.('/account', '')} />
            ))}
          </FramerRouter>
        </main>
      </div>
    </>
  )
}

export default AccountsDrawer
