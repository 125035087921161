// dependencies
import React, { useEffect, useState } from 'react'
import _isUndefined from 'lodash/isUndefined'
import Layout from '@components/layout'
import { CircularProgress, Box } from '@mui/material'
import { navigate } from 'gatsby'
import { useFirebaseContext } from '@context/firebaseContext'
// components
import AccountsDrawer from '@components/accounts/AccountsDrawer'

const AccountPage = () => (
  <Layout>
    <AccountsDrawer />
  </Layout>
)

export default AccountPage
