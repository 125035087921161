// icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import LockIcon from '@material-ui/icons/Lock'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined'
import PersonIcon from '@material-ui/icons/Person'
import LogoutIcon from '@material-ui/icons/ExitToApp'

export default [
  {
    id: `mobile-account`,
    path: null,
    label: 'Profile',
    activeIcon: AccountCircleIcon,
    icon: AccountCircleOutlinedIcon,
    menu: [
      {
        id: `mobile-profile-1`,
        path: '/account/profile',
        label: 'Profile',
        icon: PersonIcon,
      },
      {
        id: 'mobile-password-2',
        path: '/account/password',
        label: 'Password',
        icon: LockIcon,
      },
    ],
  },
  {
    id: 'mobile-orders-2',
    path: '/order/status',
    label: 'Orders',
    activeIcon: LocalOfferIcon,
    icon: LocalOfferOutlinedIcon,
  },
]
