// icons
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined'
import LockIcon from '@material-ui/icons/LockOutlined'
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined'
import FavoriteIcon from '@material-ui/icons/FavoriteBorderOutlined'
import PersonIcon from '@material-ui/icons/PersonOutline'
// components
import ProfilePage from '../ProfilePage'
import PasswordPage from '../PasswordPage'
import OrdersPage from '../OrdersPage'

export default [
  {
    id: `profile-1`,
    path: '/account/profile',
    component: ProfilePage,
    label: 'Profile',
    icon: AccountCircleIcon,
  },
  {
    id: 'order-3',
    path: '/order/status',
    component: OrdersPage,
    label: 'Orders',
    icon: LocalOfferIcon,
  },
  {
    id: 'password-2',
    path: '/account/password',
    label: 'Password',
    icon: LockIcon,
    component: PasswordPage,
  },
]
